import Mustache from "mustache";
import { Controller } from "@hotwired/stimulus";
import Diff2HtmlUI from "../../../../../packs/controllers/diff2html_controller";

import "highlight.js/styles/github.css";

const commentsEnabledTemplate = `
<tr>
    <td class="{{lineClass}} {{type}}">
      {{{lineNumber}}}
    </td>
    <td class="{{type}}">
        <div class="{{contentClass}}">
        {{#prefix}}
            <span class="d2h-code-line-prefix">{{{prefix}}}</span>
        {{/prefix}}
        {{^prefix}}
            <span class="d2h-code-line-prefix">&nbsp;</span>
        {{/prefix}}
        {{#content}}
            <span class="d2h-code-line-ctn">{{{content}}}</span>
        {{/content}}
        {{^content}}
            <span class="d2h-code-line-ctn"><br></span>
        {{/content}}
        </div>
        <div class="comment-slot" data-filename="{{file.newName}}" data-line-number-before="{{line.oldNumber}}" data-line-number-after="{{line.newNumber}}"></div>
        <button
          aria-label="Add new comment on line {{line.oldNumber}}/{{line.newNumber}} of file '{{file.newName}}'"
          class="new_comment"
          data-action="click->students--tasks--solutions--show--diff-viewer-component#newComment"
          data-filename="{{file.newName}}"
          data-line-number-before="{{line.oldNumber}}"
          data-line-number-after="{{line.newNumber}}"
          type="button"
        >+</button>
    </td>
</tr>
`;

const commentsDisabledTemplate = `
<tr>
    <td class="{{lineClass}} {{type}}">
      {{{lineNumber}}}
    </td>
    <td class="{{type}}">
        <div class="{{contentClass}}">
        {{#prefix}}
            <span class="d2h-code-line-prefix">{{{prefix}}}</span>
        {{/prefix}}
        {{^prefix}}
            <span class="d2h-code-line-prefix">&nbsp;</span>
        {{/prefix}}
        {{#content}}
            <span class="d2h-code-line-ctn">{{{content}}}</span>
        {{/content}}
        {{^content}}
            <span class="d2h-code-line-ctn"><br></span>
        {{/content}}
        </div>
        <div class="comment-slot" data-filename="{{file.newName}}" data-line-number-before="{{line.oldNumber}}" data-line-number-after="{{line.newNumber}}"></div>
    </td>
</tr>
`;

const fileSummaryWrapperTemplate = `
<div class="d2h-file-list-wrapper {{colorScheme}}">
    <button class="group d2h-file-list-header" aria-label="Toggle file list" aria-expanded="{{fileListStartVisible}}" data-action="click->students--tasks--solutions--show--diff-viewer-component#updateFileListHeight" type="button">
        <span class="group-aria-expanded:block d2h-file-list-title">Files</span>
        <span class="d2h-file-list-number hidden group-aria-expanded:flex">{{filesNumber}}</span>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-gray-600 hidden group-aria-expanded:block"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M9 4v16" /><path d="M15 10l-2 2l2 2" /></svg>
        <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="text-gray-600 block group-aria-expanded:hidden"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" /><path d="M15 4v16" /><path d="M9 10l2 2l-2 2" /></svg>
    </button>
    <div class="d2h-file-list">
    {{{files}}}
    </div>
</div>
`;

const fileSummaryLineTemplate = `
<li class="d2h-file-list-line">
    <a href="#{{fileName}}" class="d2h-file-name-wrapper" title="{{fileName}}">
    <svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="min-w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /></svg>
    <p class="d2h-file-name">{{fileName}}</p>
    {{>fileIcon}}
    </a>
</li>
`;

const lineByLineFileDiffTemplate = `
<div
 {{#file.isDeleted}}id="{{file.oldName}}"{{/file.isDeleted}}
 {{#file.isRenamed}}id="{{file.oldName}}"{{/file.isRenamed}}
 {{#file.isNew}}id="{{file.newName}}"{{/file.isNew}}
  id="{{file.newName}}"
  class="d2h-file-wrapper"
  data-lang="{{file.language}}"
  {{#file.isCombined}}data-iscombined="true"{{/file.isCombined}}
  {{#file.isDeleted}}data-file-state="deleted"{{/file.isDeleted}}
  {{#file.isNew}}data-file-state="new"{{/file.isNew}}
  {{#file.isCopy}}data-file-state="copied"{{/file.isCopy}}
  {{#file.isRename}}data-file-state="renamed"{{/file.isRename}}
  data-added-lines="{{file.addedLines}}"
  data-deleted-lines="{{file.deletedLines}}"
>
  <div class="d2h-file-header">
    {{{filePath}}}
  </div>
  <div class="d2h-file-diff">
    <div class="d2h-code-wrapper">
      <table class="d2h-diff-table">
          <tbody class="d2h-diff-tbody">
            {{#file.isDeleted}}
              <div class="d2h-file-deleted">
                <button class="common_button standard primary normal none sm" aria-label="Show file content" aria-expanded="true" type="button" data-file-name="" data-action="click->students--tasks--solutions--show--diff-viewer-component#showDeletedFile">
                  Load file content
                </button>
                <span>This file has been deleted.</span>
              </div>
            {{/file.isDeleted}}
            {{^file.isDeleted}}
              {{{diffs}}}
            {{/file.isDeleted}}
          </tbody>
      </table>
    </div>
  </div>
</div>
`;

const genericFilePathTemplate = `
<span class="d2h-file-name-wrapper">
  <span class="d2h-lines-changed"></span>
  <span class="d2h-file-name" title="{{fileDiffName}}">{{fileDiffName}}</span>
</span>
<label class="d2h-file-collapse">
  <input class="d2h-file-collapse-input" type="checkbox" name="viewed" value="viewed">
  Viewed
</label>
`;

const diffTooBigMessageTemplate = `
<div class="d2h-file-too-big">
  <p>File too large to display</p>
  <span>This file will be skipped</span>
</div>
`;

const fileChangedIconTemplate = `
<svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#d0b44c" stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M12 10l0 4" /><path d="M10 12l4 0" /><path d="M10 17l4 0" /></svg>
`;

const fileAddedIconTemplate = `
<svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#399839"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M12 11l0 6" /><path d="M9 14l6 0" /></svg>
`;

const fileDeletedIconTemplate = `
<svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#c33"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M9 14l6 0" /></svg>
`;

const fileRenamedIconTemplate = `
<svg  xmlns="http://www.w3.org/2000/svg"  width="20"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="#3572b0"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="icon icon-tabler icons-tabler-outline icon-tabler-file-pencil"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /><path d="M10 18l5 -5a1.414 1.414 0 0 0 -2 -2l-5 5v2h2z" /></svg>
`;

const fileListIconTemplate = {
  new: `
 <svg aria-hidden="true" class="d2h-icon d2h-added" height="16" title="added" version="1.1" viewBox="0 0 14 16"
     width="14">
    <path d="M13 1H1C0.45 1 0 1.45 0 2v12c0 0.55 0.45 1 1 1h12c0.55 0 1-0.45 1-1V2c0-0.55-0.45-1-1-1z m0 13H1V2h12v12zM6 9H3V7h3V4h2v3h3v2H8v3H6V9z"></path>
</svg>
`,
  deleted: `
  <svg aria-hidden="true" class="d2h-icon d2h-deleted" height="16" title="removed" version="1.1"
     viewBox="0 0 14 16" width="14">
    <path d="M13 1H1C0.45 1 0 1.45 0 2v12c0 0.55 0.45 1 1 1h12c0.55 0 1-0.45 1-1V2c0-0.55-0.45-1-1-1z m0 13H1V2h12v12zM11 9H3V7h8v2z"></path>
</svg>
`,
  changed: `
  <svg aria-hidden="true" class="d2h-icon d2h-changed" height="16" title="modified" version="1.1"
     viewBox="0 0 14 16" width="14">
    <path d="M13 1H1C0.45 1 0 1.45 0 2v12c0 0.55 0.45 1 1 1h12c0.55 0 1-0.45 1-1V2c0-0.55-0.45-1-1-1z m0 13H1V2h12v12zM4 8c0-1.66 1.34-3 3-3s3 1.34 3 3-1.34 3-3 3-3-1.34-3-3z"></path>
</svg>
`,
  renamed: `
  <svg aria-hidden="true" class="d2h-icon d2h-moved" height="16" title="renamed" version="1.1"
     viewBox="0 0 14 16" width="14">
    <path d="M6 9H3V7h3V4l5 4-5 4V9z m8-7v12c0 0.55-0.45 1-1 1H1c-0.55 0-1-0.45-1-1V2c0-0.55 0.45-1 1-1h12c0.55 0 1 0.45 1 1z m-1 0H1v12h12V2z"></path>
</svg>
`,
};

const diffIconTemplates = {
  changed: fileChangedIconTemplate,
  new: fileAddedIconTemplate,
  deleted: fileDeletedIconTemplate,
  renamed: fileRenamedIconTemplate,
};

const DIFF_MAX_LINE_LENGTH = 650;
const DIFF_MAX_CHANGES = 650;

export default class extends Controller {
  initialize() {
    this.drawFileListEnabled = this.element.dataset.drawFileList === "true";
    this.commentsEnabled = this.element.dataset.commentsEnabled === "true";
  }

  connect() {
    this.commentChildren = [...this.element.children];

    this.cacheCommentTemplates();

    // Parse the diff text into an array of objects
    const parsedDiffs = this.parseDiff(this.element.dataset.diff);
    const sortedDiffs = this.sortDiffs(parsedDiffs);

    this.drawDiffViewer(sortedDiffs);

    const updatedDiffs = this.updateDiffHeaders(sortedDiffs);

    // Find all deleted files ( diffs ) and adjust their id
    this.updateDeletedFileAttributes();
    this.renderFileTree(updatedDiffs);
    this.normalizeFileIds();
    this.replaceCommentSlots();

    if (!this.drawFileListEnabled) return;

    // Handle file list height on scroll
    this.initializeListHeight();
  }

  disconnect() {
    window.removeEventListener("scroll", this.updateFileListHeight.bind(this));
  }

  // ********** Diff Viewer **********

  getHtmlDiffOptions(commentsEnabled, drawFileListEnabled, rawTemplates = {}) {
    return {
      drawFileList: drawFileListEnabled,
      fileListStartVisible: drawFileListEnabled,
      fileContentToggle: true,
      fileListToggle: true,
      highlight: false,
      matching: "none",
      rawTemplates: {
        "generic-line": commentsEnabled
          ? commentsEnabledTemplate
          : commentsDisabledTemplate,
        "generic-file-path": genericFilePathTemplate,
        "file-summary-wrapper": fileSummaryWrapperTemplate,
        "file-summary-line": fileSummaryLineTemplate,
        "line-by-line-file-diff": lineByLineFileDiffTemplate,
        ...rawTemplates,
      },
      diffMaxLineLength: DIFF_MAX_LINE_LENGTH,
      diffMaxChanges: DIFF_MAX_CHANGES,
      diffTooBigMessage: () => diffTooBigMessageTemplate,
    };
  }

  drawDiffViewer(diffs) {
    if (diffs.length === 0) return;

    const htmlDiffOptions = this.getHtmlDiffOptions(
      this.commentsEnabled,
      this.drawFileListEnabled && diffs.length > 1
    );

    const combinedDiff = diffs.map((diff) => diff.diff).join("\n");

    const htmlDiff = new Diff2HtmlUI(
      this.element,
      combinedDiff,
      htmlDiffOptions
    );

    htmlDiff.draw();
    htmlDiff.highlightCode();
  }

  getScrollableElement() {
    const scrollableElements = [
      document.documentElement,
      document.body,
      document.querySelector("main"),
    ];
    for (const element of scrollableElements) {
      if (this.isScrollable(element)) {
        return element;
      }
    }
    return null;
  }

  isScrollable(element) {
    const overflowY = window.getComputedStyle(element).overflowY;
    const isScrollable = overflowY !== "visible" && overflowY !== "hidden";
    return isScrollable && element.scrollHeight > element.clientHeight;
  }

  navigateToDiff(event) {
    const target = event.target.closest("button");
    const id = target.dataset.id;

    if (!id) return;

    this.element.querySelectorAll(".d2h-file-wrapper").forEach((container) => {
      if (!container.classList.contains("active")) return;

      container.classList.remove("active");
    });
    this.element
      .querySelectorAll("button.d2h-file-name-wrapper")
      .forEach((link) => {
        if (!link.classList.contains("active")) return;

        link.classList.remove("active");
      });

    const diffElement = this.element.querySelector(`div[id="${id}"]`);

    if (!diffElement) return;

    diffElement.classList.add("active");
    target.classList.add("active");

    const diffElementOffset = diffElement.offsetTop;
    const stickyHeader = document.querySelector(
      "div[data-controller='sticky']"
    );

    const topOffset = stickyHeader?.offsetHeight || 16;

    const scrollableElement = this.getScrollableElement();

    if (!scrollableElement) {
      window.scrollTo({
        top: diffElementOffset - topOffset,
        behavior: "smooth",
      });
      return;
    }

    scrollableElement.scrollTo({
      top: diffElementOffset - topOffset,
      behavior: "smooth",
    });
  }

  // ********** File List Height **********

  initializeListHeight() {
    // Update file list height on load
    this.updateFileListHeight();

    window.addEventListener("scroll", this.updateFileListHeight.bind(this));
  }

  // Update the height of the file list on scroll
  updateFileListHeight() {
    if (!this.element) return;

    const fileList = this.element.querySelector(
      ".d2h-file-list-wrapper.expanded"
    );
    const footer = document.querySelector("footer");

    if (!fileList) return;

    const windowHeight = window.innerHeight;
    const fileListRect = fileList.getBoundingClientRect();
    const topValue = parseInt(getComputedStyle(fileList).top.slice(0, -2));
    const fileListTopOffset =
      fileListRect.top >= topValue ? fileListRect.top : topValue;

    let availableHeight = windowHeight - fileListTopOffset;

    if (footer) {
      const footerRect = footer.getBoundingClientRect();

      // Check if footer is in view
      if (footerRect.top < windowHeight && footerRect.bottom > 0) {
        // Calculate the visible part of the footer
        const visibleFooterHeight = windowHeight - footerRect.top;

        availableHeight -= visibleFooterHeight;
      }
    }

    fileList.style.height = `${availableHeight}px`;
  }

  // ********** File List Tree **********

  toggleFolder(event) {
    const target = event.target.closest(".file-tree-folder-name");

    target
      .querySelector(".file-tree-folder-arrow")
      .classList.toggle("rotate-90");
  }

  // Merge single child folders in the file tree
  mergeSingleChildFolders(node) {
    if (!node) return node;

    const keys = Object.keys(node);
    const newNode = {};

    keys.forEach((key) => {
      const childNode = node[key];
      if (!childNode || !childNode.children) {
        newNode[key] = childNode;
        return;
      }

      const mergedChildNode = this.mergeSingleChildFolders(childNode.children);
      const childKeys = Object.keys(mergedChildNode);

      if (childKeys.length === 1 && !childNode.__isFile) {
        const singleChildKey = childKeys[0];
        const singleChild = mergedChildNode[singleChildKey];

        if (!singleChild.__isFile) {
          const newKey = key + "/" + singleChildKey;
          newNode[newKey] = {
            ...singleChild,
            __name: newKey,
          };
        } else {
          newNode[key] = {
            ...childNode,
            children: mergedChildNode,
          };
        }
      } else {
        newNode[key] = {
          ...childNode,
          children: mergedChildNode,
        };
      }
    });

    return newNode;
  }

  buildFileTree(diffs) {
    const tree = {};

    diffs.forEach((diff) => {
      const parts = diff.fileName.split("/");
      let current = tree;

      parts.forEach((part, index) => {
        const isFile = index === parts.length - 1;
        const fileName = diff.fileName.replace(/\s+/g, "");

        if (!current[part]) {
          current[part] = {
            __isFile: isFile,
            __name: part,
            __path: isFile ? fileName : null,
            __status: diff.status,
            children: {},
          };
        }

        current = current[part].children;
      });
    });

    const mergedTree = this.mergeSingleChildFolders(tree);
    return mergedTree;
  }

  generateFileTreeHtml(tree, isRoot = true) {
    let html = "";

    if (isRoot) {
      html += '<ul class="file-tree-root">';
    } else {
      html += '<ul class="[&>li]:pl-4">';
    }

    Object.keys(tree).forEach((key) => {
      if (key.startsWith("__")) return;

      const node = tree[key];

      if (node.__isFile) {
        html += `
            <li class="file-tree-file">
              <button type="button" data-id="${
                node.__path
              }" class="d2h-file-name-wrapper" data-action="click->students--tasks--solutions--show--diff-viewer-component#navigateToDiff" title="${
          node.__name
        }">
              <svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="min-w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M14 3v4a1 1 0 0 0 1 1h4" /><path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" /></svg>
              <p class="d2h-file-name">${node.__name}</p>
              ${fileListIconTemplate[node.__status]}
              </button>
            </li>
          `;
      } else {
        html += `
            <li class="file-tree-folder">
              <details class="file-tree-folder-details" open>
                <summary class="file-tree-folder-name" data-action="click->students--tasks--solutions--show--diff-viewer-component#toggleFolder">
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="20"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="file-tree-folder-arrow min-w-4 transition-transform rotate-90"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 6l6 6l-6 6" /></svg>
                  <svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="20"  viewBox="0 0 24 24"  fill="currentColor" class="min-w-4"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M9 3a1 1 0 0 1 .608 .206l.1 .087l2.706 2.707h6.586a3 3 0 0 1 2.995 2.824l.005 .176v8a3 3 0 0 1 -2.824 2.995l-.176 .005h-14a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-11a3 3 0 0 1 2.824 -2.995l.176 -.005h4z" /></svg>
                  <span title="${node.__name}">${node.__name}</span>
                </summary>
                ${this.generateFileTreeHtml(node.children, false)}
              </details>
            </li>
          `;
      }
    });

    html += "</ul>";

    return html;
  }

  renderFileTree(diffs) {
    const fileTree = this.buildFileTree(diffs);
    const fileTreeHtml = this.generateFileTreeHtml(fileTree);

    const fileListElement = this.element.querySelector(".d2h-file-list");
    if (!fileListElement) return;

    fileListElement.innerHTML = fileTreeHtml;
  }

  // ********** Deleted File Diff **********

  updateDeletedFileAttributes() {
    // Find all elements representing deleted files and update their attributes
    const deletedFiles = this.element.querySelectorAll("[data-file-name]");

    deletedFiles.forEach((deletedFile) => {
      const parentDiff = deletedFile.closest(".d2h-file-wrapper");
      const fileName = parentDiff.querySelector(".d2h-file-name").textContent;

      if (!fileName) return;

      deletedFile.setAttribute("data-file-name", fileName);
    });
  }

  // Show the deleted file diff
  showDeletedFile({ target }) {
    const fileName = target.dataset.fileName.replace(/\s+/g, "");

    const fileWrapper = document.getElementById(fileName);

    if (!fileWrapper) return;

    fileWrapper.querySelector(".d2h-file-deleted").remove();

    const diffs = this.parseDiff(this.element.dataset.diff);

    const diff = diffs.find(
      (diff) => diff.fileName.replace(/\s+/g, "") === fileName
    )?.diff;

    if (!diff) {
      console.error(`No diff found for file: ${fileName}`);
      return;
    }

    const htmlDiffOptions = this.getHtmlDiffOptions(
      this.commentsEnabled,
      false,
      {
        "line-by-line-file-diff": `
            <div class="d2h-code-wrapper">
                <table class="d2h-diff-table">
                    <tbody class="d2h-diff-tbody">
                    {{{diffs}}}
                    </tbody>
                </table>
            </div>`,
      }
    );

    const diff2htmlUi = new Diff2HtmlUI(
      fileWrapper.querySelector(".d2h-file-diff"),
      diff,
      htmlDiffOptions
    );

    diff2htmlUi.draw();
    diff2htmlUi.highlightCode();
  }

  // ********** Diffs Parsing **********

  // Parse the diff text into an array of objects
  parseDiff(diffText) {
    // Split the text by "diff --git" to separate each file diff section
    const diffSections = diffText.split(/^diff --git/gm).slice(1);

    // Map each section to an object with its raw content
    const changes = diffSections.map((section) => {
      // Ensure that each section starts with the "diff --git" header
      const diff = `diff --git${section}`.trim();
      const fileName = section.split("\n")[0].split(" b/")[1];

      return {
        fileName,
        diff,
      };
    });

    return changes;
  }

  // Sort the diffs by file name
  sortDiffs(diffs) {
    return diffs.sort((a, b) => {
      const aIsRoot = !a.fileName.includes("/");
      const bIsRoot = !b.fileName.includes("/");

      if (aIsRoot && bIsRoot) {
        return a.fileName.localeCompare(b.fileName);
      }

      if (aIsRoot) {
        return 1;
      }

      if (bIsRoot) {
        return -1;
      }

      return a.fileName.localeCompare(b.fileName);
    });
  }

  // ********** Comments **********

  cacheCommentTemplates() {
    this.newCommentFormTemplate =
      this.element.querySelector("#newCommentForm")?.innerHTML;
    this.editCommentFormTemplate =
      this.element.querySelector("#editCommentForm")?.innerHTML;
  }

  replaceCommentSlots() {
    if (!this.commentChildren) return;

    this.commentChildren.forEach((commentList) => {
      const { diffTarget, filename, lineNumber } = commentList.dataset;
      const lineNumberSelector = `[data-line-number-${diffTarget}="${lineNumber}"]`;
      const commentSlot = this.element.querySelector(
        `.comment-slot[data-filename="${filename}"]${lineNumberSelector}`
      );
      if (commentSlot) {
        commentSlot.replaceChildren(commentList);
      }
    });
  }

  editComment({ target }) {
    const commentElement = target.closest(".comment");
    const isFormVisible = commentElement.querySelector("form[id*='comment']");

    if (isFormVisible) return;

    const { body, diffTarget, filename, id, lineNumber } = target.dataset;
    const variables = { body, diffTarget, filename, lineNumber };

    const commentBody = commentElement.querySelector(".body");
    commentBody.style.display = "none";

    commentElement.insertAdjacentHTML(
      "beforeend",
      Mustache.render(this.editCommentFormTemplate, variables)
    );

    // NOTE: this is a fix, because rails escapes the action URL no matter what, so we can't put mustache there
    const formElement = commentElement.querySelector("form[id*='comment']");

    formElement.action = formElement.action + `/${id}`;
  }

  newComment({ target }) {
    const { filename, lineNumberBefore, lineNumberAfter } = target.dataset;
    const variables = {
      diffTarget: this.#getDiffTarget(lineNumberBefore, lineNumberAfter),
      filename,
      lineNumber: lineNumberAfter || lineNumberBefore,
    };

    this.#getCommentSlot(
      filename,
      lineNumberBefore,
      lineNumberAfter
    ).innerHTML = Mustache.render(this.newCommentFormTemplate, variables);
  }

  #getCommentSlot(filename, lineNumberBefore, lineNumberAfter) {
    return this.element.querySelector(
      `.comment-slot[data-filename="${filename}"][data-line-number-before="${lineNumberBefore}"][data-line-number-after="${lineNumberAfter}"]`
    );
  }

  #getDiffTarget(lineNumberBefore, lineNumberAfter) {
    return lineNumberAfter ? "after" : "before";
  }

  // ********** Improve Diff Viewer **********

  updateDiffHeaders(diffs) {
    const diffList = this.element.querySelectorAll(".d2h-file-wrapper");
    const files = [];

    diffList.forEach((diff) => {
      const { id, fileState, addedLines, deletedLines } = diff.dataset;
      const linesChangesElement = diff.querySelector(".d2h-lines-changed");
      const status = fileState || "changed";

      files.push({ addedLines, deletedLines, status, id });

      const linesChanges = parseInt(addedLines) + parseInt(deletedLines);
      if (linesChangesElement) {
        linesChangesElement.textContent = linesChanges;
      }

      const fileWrapper = diff.querySelector(".d2h-file-name-wrapper");
      if (fileWrapper) {
        const fileIcon = diffIconTemplates[status];
        if (fileIcon) {
          fileWrapper.insertAdjacentHTML("afterbegin", fileIcon);
        }
      }
    });

    const updatedDiffs = diffs.map((diff, index) => {
      const file = files[index];
      return { ...diff, ...file };
    });

    return updatedDiffs;
  }

  normalizeFileIds() {
    const diffsWrapper = this.element.querySelector(".d2h-wrapper");

    if (!diffsWrapper) return;
    const files = diffsWrapper.querySelectorAll("div[id]");

    files.forEach((file) => {
      const fileName = file.getAttribute("id");
      const normalizedFileName = this.removeSpaceFromFileName(fileName);

      file.setAttribute("id", normalizedFileName);
    });
  }

  // **********************************

  removeSpaceFromFileName(fileName) {
    return fileName.replace(/\s+/g, "");
  }
}
