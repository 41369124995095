import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.el = this.element;
    this.summary = this.element.querySelector("summary");
    this.content = this.contentTarget;
    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;
  }

  disconnect() {
    if (!this.animation) return;

    this.animation.cancel();
    this.animation = null;
  }

  toggle(event) {
    if (!this.hasContentTarget) {
      console.error("Accordion content target is missing");
      return;
    }

    // Prevent toggling when clicking on a link or button inside the summary
    if (event.target.closest("a") || event.target.closest("button")) return;

    event.preventDefault();

    this.el.style.overflow = "hidden";

    if (this.isClosing || !this.el.open) {
      this.expand();
    } else if (this.isExpanding || this.el.open) {
      this.shrink();
    }
  }

  shrink() {
    this.isClosing = true;

    const startHeight = `${this.el.offsetHeight}px`;
    const endHeight = `${this.summary.offsetHeight}px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.el.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: "ease-out",
      }
    );

    this.animation.onfinish = () => this.onAnimationFinish(false);
    this.animation.oncancel = () => (this.isClosing = false);
  }

  expand() {
    this.el.style.height = `${this.el.offsetHeight}px`;
    this.el.open = true;
    window.requestAnimationFrame(() => this.doExpand());
  }

  doExpand() {
    this.isExpanding = true;
    const startHeight = `${this.el.offsetHeight}px`;
    const endHeight = `${
      this.summary.offsetHeight + this.content.offsetHeight
    }px`;

    if (this.animation) {
      this.animation.cancel();
    }

    this.animation = this.el.animate(
      {
        height: [startHeight, endHeight],
      },
      {
        duration: 400,
        easing: "ease-out",
      }
    );

    this.animation.onfinish = () => this.onAnimationFinish(true);
    this.animation.oncancel = () => (this.isExpanding = false);
  }

  onAnimationFinish(open) {
    this.el.open = open;
    this.animation = null;
    this.isClosing = false;
    this.isExpanding = false;
    this.el.style.height = this.el.style.overflow = "";
  }
}
