export function initializeTurboConfirm(application) {
  Turbo.setConfirmMethod((title, element) => {
    const dialog = document.getElementById("turbo-confirm");

    if (!dialog) {
      console.error("turbo-confirm dialog not found");
      return;
    }

    // Reset the dialog's returnValue to null
    dialog.returnValue = null;

    const { confirmationType, message } = getConfirmationAttributes(element);

    populateDialogContent(dialog, title, message, confirmationType);

    const turboModalController =
      application.getControllerForElementAndIdentifier(
        dialog,
        "common--turbo-modal"
      );

    if (!turboModalController) return;

    turboModalController.open();

    return new Promise((resolve) => {
      dialog.addEventListener(
        "close",
        () => {
          resolve(dialog.returnValue == "confirm");
        },
        { once: true }
      );
    });
  });
}

const confirmationTypes = [
  {
    name: "delete",
    buttonClass: "negative",
    buttonText: "delete",
  },
  {
    name: "confirm",
    buttonClass: "standard",
    buttonText: "confirm",
  },
  {
    name: "cancel",
    buttonClass: "negative",
    buttonText: "cancel",
  },
];

function populateDialogContent(dialog, title, message, type = "confirm") {
  const confirmButton = dialog.querySelector("button[value=confirm]");

  dialog.querySelector("h3").textContent = title;
  dialog.querySelector("p").textContent =
    message || "This action cannot be undone.";

  const confirmationType = confirmationTypes.find(
    (typeObj) => typeObj.name === type
  );

  // Our button has a set of classes, and the second class in the list (index 1)
  // is always the style class that changes the overall appearance of the button.
  // e.g. "standard" or "negative"
  const buttonClassToChange = confirmButton.classList[1];

  if (confirmationType) {
    confirmButton.textContent = confirmationType.buttonText;
    confirmButton.classList.replace(
      buttonClassToChange,
      confirmationType.buttonClass
    );

    return;
  }

  const defaultConfirmationType = confirmationTypes.find(
    (typeObj) => typeObj.name === "confirm"
  );

  confirmButton.textContent = defaultConfirmationType.buttonText;
  confirmButton.classList.replace(
    buttonClassToChange,
    defaultConfirmationType.buttonClass
  );
}

function getConfirmationAttributes(element) {
  if (element.tagName !== "FORM") {
    return {
      confirmationType: element.getAttribute("data-turbo-confirm-type"),
      message: element.getAttribute("data-turbo-confirm-message"),
    };
  }

  // button_to form - look for submit button
  const submitButton = element.querySelector("button[type=submit]");

  if (submitButton) {
    return {
      confirmationType: submitButton.getAttribute("data-turbo-confirm-type"),
      message: submitButton.getAttribute("data-turbo-confirm-message"),
    };
  }

  // form_tag/link_to form - look for method attribute
  return {
    confirmationType: element.getAttribute("method"),
    message: null,
  };
}
