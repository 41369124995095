import { Modal } from "tailwindcss-stimulus-components";
import { enter, leave } from "el-transition";

export default class extends Modal {
  static targets = ["dialog", "container", "backdrop", "panel"];
  static values = {
    rerender: Boolean,
    closeOnSubmit: Boolean,
    modalType: String,
  };

  connect() {
    this.isConfirmationModal = this.modalTypeValue === "confirmation";

    if (!this.rerenderValue) {
      enter(this.backdropTarget);
      enter(this.panelTarget);
    }

    super.connect();

    if (!this.isConfirmationModal) {
      this.open();
    }

    if (!this.closeOnSubmitValue) return;

    this.handleTurboSubmitEnd = this.close.bind(this);
    this.element.addEventListener(
      "turbo:submit-end",
      this.handleTurboSubmitEnd
    );
  }

  disconnect() {
    this.unlockScroll();

    if (this.closeOnSubmitValue) {
      this.element.removeEventListener(
        "turbo:submit-end",
        this.handleTurboSubmitEnd
      );
    }
    this.unlockScroll();
    super.disconnect();
  }

  open() {
    this.lockScroll();

    if (!this.rerenderValue) {
      enter(this.backdropTarget);
      enter(this.panelTarget);
    }

    super.open();
    this.openValue = true;
  }

  close(e) {
    e?.preventDefault();

    Promise.all([leave(this.backdropTarget), leave(this.panelTarget)]).then(
      () => {
        if (!this.isConfirmationModal) {
          this.element.parentElement?.removeAttribute("src");
          this.element.remove();

          return;
        }

        this.dialogTarget.close();
        this.backdropTarget.classList.remove("hidden");
        this.panelTarget.classList.remove("hidden");
      }
    );

    this.unlockScroll();
    this.openValue = false;
  }

  preventClose(event) {
    event.preventDefault();
  }

  beforeCache() {
    if (!this.isConfirmationModal) return;

    this.close();
  }

  lockScroll() {
    document.body.classList.add("overflow-hidden");
  }

  unlockScroll() {
    document.body.classList.remove("overflow-hidden");
  }

  closeBackground(event) {
    if (event.target === this.containerTarget) this.close();
  }
}
