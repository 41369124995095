import { Controller } from "@hotwired/stimulus";
import Typed from "typed.js";

// Connects to data-controller="animated-text"
export default class extends Controller {
  static targets = ["strike", "write"];

  static values = {
    write: { type: Array, default: [] },
    loop: { type: Boolean, default: false },
    backSpeed: { type: Number, default: 20 },
    typeSpeed: { type: Number, default: 75 },
    startDelay: { type: Number, default: 2000 },
  };

  connect() {
    // Turn on the strike-through effect
    if (this.hasStrikeTarget)
      this.strikeTarget.classList.add(
        "after:content-['']",
        "after:scale-x-100"
      );

    this.initTyped();

    this.initObserver();
  }

  disconnect() {
    if (this.observer) this.observer.disconnect();

    if (this.typed) {
      this.typed.destroy();
      this.typed = null;
    }
  }

  initTyped() {
    const options = {
      strings: this.writeValue,
      backSpeed: this.backSpeedValue,
      typeSpeed: this.typeSpeedValue,
      startDelay: this.startDelayValue,
      showCursor: true,
      loop: this.loopValue,
      onBegin: (self) => {
        setTimeout(() => {
          if (!self.cursor) return;

          self.cursor.classList.add("inline-block");
        }, this.startDelayValue);
      },
      onComplete: (self) => {
        if (!self.cursor) return;

        self.cursor.remove();
      },
    };

    this.typed = new Typed(this.writeTarget, options);
  }

  initObserver() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    this.handleIntersect = this.handleIntersect.bind(this);

    this.observer = new IntersectionObserver(this.handleIntersect, options);
    this.observer.observe(this.element);
  }

  handleIntersect(entries, _observer) {
    if (!this.typed) return;

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.typed.start();
      } else {
        this.typed.stop();
      }
    });
  }
}
