import { Application } from "@hotwired/stimulus";
import { initializeTurboConfirm } from "./initialize_turbo_confirm";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

if (window.Turbo) {
  initializeTurboConfirm(application);
}

export { application };
