import { Controller } from "@hotwired/stimulus";

// This controller is used to toggle the visibility of an element when any reference elements are in view.

export default class extends Controller {
  static targets = ["toggle", "reference"];

  connect() {
    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }
    );

    this.referenceTargets.forEach((reference) => {
      this.observer.observe(reference);
    });
  }

  disconnect() {
    this.observer.disconnect();
  }

  handleIntersection(entries) {
    const isAnyReferenceVisible = entries.some((entry) => entry.isIntersecting);

    if (isAnyReferenceVisible) {
      this.toggleTarget.classList.add("hidden");
    } else {
      this.toggleTarget.classList.remove("hidden");
    }
  }
}
